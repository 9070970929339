import React from 'react'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import DocFile from "../dashboard/docfile"
//import ModulePopup from "../dashboard/model-popup"
import './style.css'
import api from "../../apiUrl";
import Casecompo from '../casecompo';
import jwt_decode from "jwt-decode"
function RecentCase(prop) {
    const roleId = prop.roleId
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [load, setLoad] = useState(false)
    const [client, setClient] = useState(0);
    const [clientData, setClientData] = useState([]);
    const [userData, setUserData] = useState([])
    const [clientId, setclientId] = useState(0)
    const [userId, setUserId] = useState(0)
    // const navigate = useNavigate();
    var ms3 = "ms-3"
    var apis = api()
    var jwtToken = localStorage.getItem("token")
    const jwtDecode = jwt_decode(jwtToken)
    const currentUserId = jwtDecode.Id
    const role = jwtDecode.RoleId


    function getClientAPi() {
        fetch(`${apis}Admin/GetClientList`, {
            mode: "cors",
        }).then((respon) => {
            respon.json().then((result) => {
                setClientData(result.data)
                setclientId(result.data[0].id)
            })
        })
    }
    function getUserss() {
        fetch(`${apis}Admin/GetAdminUserList`).then((respons) => {
            respons.json().then((result) => {
                console.log("getUser", result)
                setUserData(result.data)

            })
        })
    }


    function getAPi() {
        setLoad(true)
        {
            role == 2 ?
                fetch(`${apis}Admin/FindCaseForUser/${currentUserId}/RECENT`, {
                    mode: "cors",
                }).then((respon) => {
                    respon.json().then((result) => {
                        // console.log(result);
                        setData(result.data)
                        setLoad(false)
                    })
                })
                :
                fetch(`${apis}Admin/FindCases/${currentUserId}/RECENT/${clientId}/${userId}`, {
                    mode: "cors",
                }).then((respon) => {
                    respon.json().then((result) => {
                        // console.log(result);
                        setData(result.data)
                        setLoad(false)
                    })
                })
        }

    }
    useEffect(() => {
        console.log("clientId useEffect", clientId);
        if (clientId != 0) {
            getAPi()
        }
    }, [clientId, userId])

    useEffect(() => {
        getClientAPi()
        getUserss()
        // setData(json)
    }, [])
    return (
        <>
            <div className="border-bottom px-3 pt-3">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-3 col-md-4">
                            <div>
                                <label className="control-label">Client  Name</label>
                                <div className="input-group" style={{ padding: '16px 0 !important' }}>
                                    <select className="single-select form-select" id="inputGroupSelect05"
                                        onChange={(e) => {
                                            setclientId(e.target.value)
                                        }}
                                        defaultValue={0}

                                    >
                                        <option value={clientId} selected disabled>-- Select  --</option>

                                        {
                                            clientData.map((item) =>
                                                <option value={item.id} selected={item.id === clientId ? true : false}>{item.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-4">
                            {
                                roleId == "1" ?
                                    <div>
                                        <label className="control-label">GCSL Staff Name</label>
                                        <div className="input-group  mb-3" style={{ padding: '16px 0 !important' }}>
                                            <select className="single-select form-select" id="inputGroupSelect05"
                                                onChange={(e) => {
                                                    setUserId(e.target.value)
                                                }}
                                            >
                                                <option value="" disabled selected>-- Select --</option>

                                                {
                                                    userData.map((item) =>
                                                        <option value={item.userId}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        <div className="col-md-6 col-12 text-right p-0">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-xl-8 col-md-8">
                                <h5 className="mt-3 mb-0">Recent Cases</h5>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <form className="app-search d-none d-lg-block px-3 pb-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-transparent" placeholder="Search.."
                                            aria-label="Text input with dropdown button"
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {
                    load ? <div class="spin-a"><div class="spinner-border spinner-border-lg" role="status"><span class="visually-hidden">Loading...</span></div></div> :
                        <div className="row mt-4">
                            <div className="col-lg-12 col-12">
                                <ul>

                                    {
                                        data &&
                                        data.length > 0 &&
                                        data.filter((item) => {
                                            if (search === "") return item; // searchTerm is in scope?
                                            if (item.candidateName.toLowerCase().includes(search.toLowerCase())) {
                                                return item;
                                            } else {
                                                return false;
                                            }

                                        }).map((item, i) =>
                                            <Casecompo data={item}
                                            //reload={getAPi}
                                            //roleId={roleId}
                                            />)
                                    }
                                </ul>

                            </div>
                        </div>
                }


            </div>

        </>
    )
}

export default RecentCase