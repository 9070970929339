import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModulePopup from "./dashboard/model-popup"
import DocFile from "./dashboard/docfile"
import jwt_decode from "jwt-decode";
import { useState } from 'react';
import api from '../apiUrl';
import AddNewFile from './dashboard/addNewFile';
import AddReport from './dashboard/addReport';
import CaseAllocated from './dashboard/caseAllocated';
import AdddependentforAdmin from './dashboard/adddependentforAdmin';
import CaseLevelRollback from './dashboard/caseLevelRollback';
import CaseLevelRollbackComments from './dashboard/caseLevelRollbackComments';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function Casecompo(prop) {
    const [loadPdf, setLoadPdf] = useState(false)


    const item = prop.data
    //var fileUrl = `https://gcsl.api.digitalnoticeboard.biz/`
    var fileUrl = `https://api.gcspro.net/api/`
    var ms3 = "ms-3"
    // console.log("prop data", item.zipUrl[0].filePat)
    const jwtDecode = jwt_decode(localStorage.getItem("token"))
    var rol = jwtDecode.RoleId;
    const currentUserId = jwtDecode.Id
    const level = jwtDecode.Level

    var apis = api()
    const getReportFilePdf = (e) => {
        setLoadPdf(true)
        // using Java Script method to get PDF file
        fetch(`${apis}Admin/DownLoadReport/${e.id}/PDF`).then((respons) => {
            respons.text().then((result) => {
                setLoadPdf(false)
                console.log("pdf Result", apis)
                var fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = result;
                fileDownload.target = "_blank";
                fileDownload.download = `Report - ${e.candidateName}.pdf`;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            })
        })
    }

    const downloadFile = (e) => {
        setLoadPdf(true)
        // using Java Script method to get PDF file
        fetch(`${apis}Admin/GetZipFile/${e}`).then((respons) => {
            respons.text().then((result) => {
                setLoadPdf(false)
                console.log("pdf Result", apis)
                var fileDownload = document.createElement("a");
                document.body.appendChild(fileDownload);
                fileDownload.href = fileUrl + result;
                fileDownload.target = "_blank";
                fileDownload.download = `Report - ${e.candidateName}.pdf`;
                fileDownload.click();
                document.body.removeChild(fileDownload);
            })
        })
    }
    const [datacom, setDatacom] = useState([])
    function getRollBackComments() {
        console.log("item.caseId", item.id, currentUserId, item.rollBackDetails)
        rol == 1 ?
            fetch(`${apis}Admin/GetRollBackCommentsforAdmin/${item.id}`).then((respon) => {
                respon.json().then((result) => {
                    console.log("GetRollBackComments", result)
                    setDatacom(result.data)
                })
            }) :
            fetch(`${apis}Admin/GetRollBackComments/${item.id}/${currentUserId}/${level}`).then((respon) => {
                respon.json().then((result) => {
                    console.log("GetRollBackComments", result)
                    setDatacom(result.data)
                })
            })
    }
    useEffect(() => {
        getRollBackComments()
    }, [])
    const [open, setOpen] = useState(false);
    const [deleteItem, setdeleteItem] = useState()
    const handleClickOpen = (e) => {
        setOpen(true);
        setdeleteItem(e)
        console.log("deleteJson", deleteItem)
    };

    function deleteFile() {
        setOpen(false);
        var deleteJson = {
            "caseId": item.id,
            "documentId": deleteItem.documentId,
            "dependentId": deleteItem.dependentId,
            "fileName": deleteItem.fileName
        }
        fetch(`${apis}Admin/DeleteDocuments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(deleteJson),
        }).then((respon) => {
            respon.json().then((result) => {
                console.log(result);
                prop.reload()
            })
        })

    }
    const handleClose = () => {
        deleteFile()
    };
    console.log("item", item)
    return (
        <>
            <div className="card">

                <div className="card-body p-0">
                    {
                        (rol == "1" || rol == "2") && (item.caseRoleBack == true) ?

                            datacom == null || datacom == [] || datacom.length == 0 ? null :
                                <div className='caseMassgge'>
                                    <p className='caseComment'><CaseLevelRollbackComments data={datacom} caseId={item.id} currentUserId={currentUserId} reload={prop.reload} role={item.roles} rollBackDetails={item.rollBackDetails} rol={rol} caseCurrentLevel={item.caseCurrentLevel} /></p>
                                    {
                                        item.fromLevel == 0 || item.fromLevel == null ? null :
                                            <p className='caseMassageBox'> Case Rollbacked from {item.fromLevel} to {item.toLevel}</p>
                                    }

                                </div>


                            :
                            null
                    }

                    <div style={{ position: "relative" }}>
                        <div className="Q-gNw">
                            {
                                item.isCaseVerified == false && rol == "3" || rol == "4" ?
                                    <div className="dropdown float-end px-3 pt-2">
                                        <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <svg className="svg_exo2UA" aria-hidden="true" data-qa-tag="svg"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                <path fill-rule="evenodd"
                                                    d="M14 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM8 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM2 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2z">
                                                </path>
                                            </svg>
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-end">
                                            <div className="tippy-content-wrapper">
                                                <div className="_4ofq-h px-2" data-qa-tag="context-menu">
                                                    <ul className="D1W-uN list-unstyled mb-0">

                                                        {/* {
                                                                rol == "1" || rol == "2" ?
                                                                    <li className="o5ar4-">
                                                                        <a href="#!">
                                                                            <span className="rglmnm">Comment</span>
                                                                        </a>
                                                                    </li>
                                                                    : ""
                                                            } */}
                                                        {
                                                            rol == "3" || rol == "4" ?
                                                                <>
                                                                    <li className="o5ar4-">
                                                                        <Link to="/addDependent"
                                                                            state={
                                                                                {
                                                                                    fileName: item.candidateName,
                                                                                    LastActivity: item.lastActivity,
                                                                                    status: item.status,
                                                                                    id: item.id,
                                                                                    createOn: item.createOn,
                                                                                }
                                                                            }

                                                                        >
                                                                            <span className="B1piuH">
                                                                                <svg className="svg_exo2UA" aria-hidden="true" data-qa-tag="svg"
                                                                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                                                    <path
                                                                                        d="M9.997 14H2V2h5v3l3.207-3.207L9.414 1C8.835.42 7.819 0 7 0H2C.897 0 0 .897 0 2v12c0 1.104.897 2 2 2h8a2 2 0 002-2V8l-2.002 2.001L9.997 14zM4 10l-1 3 3-1 7-7-2-2-7 7zM15.402.48c-.78-.781-1.401-.576-2.391.375L12 2l2 2 1.001-1.096c1-1 1.185-1.643.401-2.424zM3 4h3v1H3V4zm0 2h2v1H3V6z">
                                                                                    </path>
                                                                                </svg>
                                                                            </span>
                                                                            <span className="rglmnm">Add Dependent</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className="o5ar4-">

                                                                        {/* <Modal show={show} onHide={handleClose}></Modal> */}
                                                                        <AddNewFile caseId={item.id} currentUserId={currentUserId} reload={prop.reload} />
                                                                    </li>

                                                                </>
                                                                :
                                                                ""
                                                        }
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }

                            {
                                rol == "1" || rol == "2" ?
                                    <div className="dropdown float-end px-3 pt-2">
                                        <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <svg className="svg_exo2UA" aria-hidden="true" data-qa-tag="svg"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                                <path fill-rule="evenodd"
                                                    d="M14 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM8 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2zM2 6c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2z">
                                                </path>
                                            </svg>
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-end">
                                            <div className="tippy-content-wrapper">
                                                <div className="_4ofq-h px-2" data-qa-tag="context-menu" style={{ width: "170px" }}>
                                                    <ul className="D1W-uN list-unstyled mb-0">

                                                        <li className="o5ar4-" style={{ paddingLeft: "0" }}>
                                                            <button onClick={() => downloadFile(item.id)} style={{ background: 'transparent', border: 'none', color: '#374498' }}>
                                                                <span className="B1piuH">
                                                                    <i class="mdi mdi-arrow-collapse-down"></i>
                                                                </span>
                                                                <span className="rglmnm">Download Files</span>
                                                            </button>
                                                            {/* <Link target='blank' to={`${fileUrl}${item.zipUrls}`} >
                                                            <span className="B1piuH">
                                                                <i class="mdi mdi-arrow-collapse-down"></i>
                                                            </span>
                                                            <span className="rglmnm">Download Files</span>
                                                        </Link> */}
                                                        </li>
                                                        {prop.roleId == 1 ?
                                                            <>
                                                                {/* {
                                                                item.status == "IN PROGRESS" || item.status == "UNDER REVIEW" || item.status == "CLOSED" ?
                                                                    <li >
                                                                        <AddReport caseId={item.id} currentUserId={currentUserId} />
                                                                    </li>
                                                                    : ""
                                                            } */}

                                                                {
                                                                    item.caseStatus == "CLOSED" ? null :
                                                                        <>
                                                                            <li className="o5ar4-" style={{ paddingLeft: "0" }}>
                                                                                <AdddependentforAdmin caseId={item.id} currentUserId={currentUserId} reload={prop.reload} />
                                                                            </li>
                                                                            <li className="o5ar4-" style={{ paddingLeft: "0", paddingTop: 0, }}>
                                                                                <CaseAllocated caseId={item.id} currentUserId={currentUserId} reload={prop.reload} role={item.roles} stage={item.stage} currentLevel={item.caseCurrentLevel} currentLevel1={item.caseCurrentLevel1} />
                                                                            </li>
                                                                        </>
                                                                }

                                                                {
                                                                    item.roles == "GCSLResearcher" || item.caseStatus == "CLOSED" ? null

                                                                        : <li className="o5ar4-" style={{ paddingLeft: "0", paddingTop: 0, }}>
                                                                            <CaseLevelRollback caseId={item.id} currentUserId={currentUserId} reload={prop.reload} role={item.roles} stage={item.stage} currentLevel={item.caseCurrentLevel} />
                                                                        </li>
                                                                }

                                                            </>
                                                            : ""}


                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }



                        </div>
                    </div>
                    <div className="row align-it">
                        <div className="col-md-3 text-center">

                            <div className="bg-light p-3">
                                <div className='client-lable'>
                                    {
                                        rol == "1" || rol == "2" ? <img className="stackholderimage" src={item.photo} /> : ""
                                    }
                                    <p>{item.clientName}</p>
                                </div>
                                <h5 className="pt-3"> {item.candidateName}</h5>
                                <div className="_8pVqqXr text-center" data-qa-tag="tag_name">
                                    <span className="PHDg5+ ">
                                        {/* <i className="fa fa-circle text-danger" aria-hidden="true"></i> */}
                                        <span>&nbsp;</span>{item.status}</span>
                                </div>
                                {/* {console.log("item item", item)} */}
                                {/* <span className="text_Tnc75r text_variant_small2_FyWe7T" data-qa-tag="text">Last activity:<b>{item.lastActivity}</b> to <b>{item.lastActivityTo}</b></span> */}
                                {
                                    rol == "1" || rol == "2" ? <p></p> : rol == "1" || rol == "2" ? <p>vdf</p> : ""
                                }
                                {item.buttonStatus && (rol == "1" || rol == "2") ?
                                    <Link to="/viewProfile" state={item} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button" data-qa-tag="uploader-drop-zone-button">View Profile</Link> :
                                    rol == "1" || rol == "2" ?
                                        <Link to="/confidential" state={
                                            {
                                                createBy: item.createBy,
                                                createOn: item.createOn,
                                                doc: item.files,
                                                id: item.id,
                                                caseName: item.candidateName,
                                                clientName: item.clientName


                                            }

                                        } className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" createBy={item.createBy}>Create Profile</Link> : ""
                                }
                                {item.isCaseVerified && (rol == "3" || rol == "4") ?
                                    // <Link to="/finalRport" state={{ dependent: false, caseId: item.id }} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3" type="button" data-qa-tag="uploader-drop-zone-button">Final report</Link>

                                    <button onClick={() => getReportFilePdf(item)} className="_2V07sR _1pRmkk _2BrVpl _1ILhXq px-4 mt-3">
                                        {
                                            loadPdf ? <div><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Downloading...</div> : "View Report"
                                        }
                                    </button>

                                    : ""
                                }

                                <div className="mt-3">
                                    {
                                        rol == "1" || rol == "2" ? <span> <sub>Created By: {item.createBy} | </sub></span> : ""
                                    }

                                    <span><sub> Created On: {item.createOn.toString().split('T')[0].trim()} </sub></span>
                                    <span>{prop.roleId == 1 && item.assignto !== "" ?
                                        <p>Case Allocated to : {item.assignto}</p>
                                        :
                                        ""
                                    }</span><br />
                                    {
                                        rol == "1" || rol == "2" ? <span>Stage: {item.caseCurrentLevel == "LEVEL0" ? "LEVEL1" : item.caseCurrentLevel}</span> : null
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 py-2">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <span className={ms3} ><span><b>{item.dependent.length === 0 ? "" : "Dependent"}</b>
                                    </span></span>
                                    <div className='modelFlex'>
                                        {
                                            item.dependent.map((popup) =>
                                                <ModulePopup
                                                    classList={"ms-3"}
                                                    badge={popup.badge}
                                                    caseId={item.id}
                                                    dependentId={popup.id}
                                                    buttonName={popup.dependentName}
                                                    status={popup.status}
                                                    statusName={popup.statusName}
                                                    dfiles={popup.dependentfiles}
                                                    createBy={item.createBy}
                                                    relation={popup.relation}
                                                    relationName={popup.relationName}
                                                    dependentcreateOn={popup.createOn}
                                                    buttonStatus={popup.buttonStatus}
                                                    caseName={popup.dependentName}
                                                    role={rol}
                                                    zip={popup.zipurlForDependent}
                                                    fileUrl={fileUrl}
                                                    reload={prop.reload}
                                                    apis={apis}
                                                    clientName={item.clientName}
                                                    roles={item.roles}
                                                    dependent={true}
                                                    delete={handleClickOpen}
                                                />
                                            )
                                        }
                                        <hr />
                                    </div>

                                </div>
                            </div>
                            <div className="row align-it mt-4">
                                {
                                    item.files.map((fileList) =>

                                        <DocFile fileID={fileList.id} fileType={fileList.fileType} fileName={fileList.fileName} filePath={fileList.filePath} delete={handleClickOpen} role={rol} caseId={item.id} reload={prop.reload} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You won't be able to revert this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteFile} autoFocus>
                        Yes, delete it!
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Casecompo